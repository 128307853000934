var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          { staticClass: "button-click-left" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "150px", "margin-right": "5px" },
                attrs: { clearable: "", placeholder: "Trạng thái" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.loginStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "loginStatus", $$v)
                  },
                  expression: "dataSearch.loginStatus",
                },
              },
              _vm._l(_vm.loginStatusList, function (item) {
                return _c("el-option", {
                  key: item.key,
                  attrs: { value: item.key, label: item.value },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "button-left-status",
                attrs: { placeholder: "Trạng thái" },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "status", $$v)
                  },
                  expression: "dataSearch.status",
                },
              },
              _vm._l(_vm.kidStatusList, function (item) {
                return _c("el-option", {
                  key: item.key,
                  attrs: { value: item.key, label: item.value },
                })
              }),
              1
            ),
            this.getAppTypeUserLogin == "plus"
              ? _c(
                  "el-select",
                  {
                    staticClass: "button-left-class",
                    attrs: { clearable: "", placeholder: "Chọn khối" },
                    on: { change: _vm.changeGradeMethod },
                    model: {
                      value: _vm.dataSearch.idGrade,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSearch, "idGrade", $$v)
                      },
                      expression: "dataSearch.idGrade",
                    },
                  },
                  _vm._l(_vm.gradeOfSchoolList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { value: item.id, label: item.gradeName },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "el-select",
              {
                staticClass: "button-left-class",
                attrs: {
                  filterable: "",
                  clearable: this.getAppTypeUserLogin == "plus",
                  placeholder: "Chọn lớp",
                },
                on: { change: _vm.searchHeaderMethod },
                model: {
                  value: _vm.dataSearch.idClass,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "idClass", $$v)
                  },
                  expression: "dataSearch.idClass",
                },
              },
              _vm._l(_vm.classList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { value: item.id, label: item.className },
                })
              }),
              1
            ),
            _c(
              "el-input",
              {
                staticClass: "button-left-class",
                staticStyle: { width: "280px" },
                attrs: {
                  placeholder: "Nhập tên, biệt danh, SĐT",
                  clearable: "",
                },
                on: {
                  clear: function ($event) {
                    return _vm.searchHeaderMethod()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchHeaderMethod()
                  },
                },
                model: {
                  value: _vm.dataSearch.nameOrPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "nameOrPhone", $$v)
                  },
                  expression: "dataSearch.nameOrPhone",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchHeaderMethod()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.searchExtendDialog()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-circle-plus",
                  style:
                    _vm.dataSearch.dateList.length > 0 ? "color: #FD4803" : "",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "button-click row-data" },
          [
            _vm.checkPermission(["kids_list_update"])
              ? _c(
                  "el-button",
                  {
                    staticClass: "button-over",
                    attrs: { type: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.createStudentDialog()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v("\n        Thêm mới\n      "),
                  ]
                )
              : _vm._e(),
            _vm.checkPermission(["kids_list_app"]) ||
            _vm.checkPermission(["kids_list_sms"])
              ? _c(
                  "el-dropdown",
                  { on: { command: _vm.handleSendNotify } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-over",
                        attrs: { type: "success" },
                      },
                      [
                        _vm._v("\n          Gửi tin\n          "),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      [
                        _vm.checkPermission(["kids_list_app"])
                          ? _c(
                              "el-dropdown-item",
                              { attrs: { command: "createNotifyApp" } },
                              [_vm._v("Gửi qua App\n          ")]
                            )
                          : _vm._e(),
                        _vm.checkPermission(["kids_list_sms"])
                          ? _c(
                              "el-dropdown-item",
                              { attrs: { command: "createNotifySms" } },
                              [_vm._v("Gửi qua SMS\n          ")]
                            )
                          : _vm._e(),
                        _vm.checkPermission(["kids_list_sms"])
                          ? _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading.fullscreen.lock",
                                    value: _vm.fullscreenLoading,
                                    expression: "fullscreenLoading",
                                    modifiers: { fullscreen: true, lock: true },
                                  },
                                ],
                                attrs: { command: "sendAccount" },
                              },
                              [_vm._v("Gửi tài khoản\n          ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-dropdown",
              { on: { command: _vm.handleCommandAction } },
              [
                _c(
                  "el-button",
                  { staticClass: "button-over", attrs: { type: "success" } },
                  [
                    _vm._v("\n          Tác vụ\n          "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  [
                    _vm.checkPermission(["kids_list_update"])
                      ? _c(
                          "span",
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "changeClass" } },
                              [_vm._v("Chuyển lớp")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "appActive" } },
                              [_vm._v("Kích hoạt App")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "appCancelActive" } },
                              [_vm._v("Hủy kích hoạt App")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "sMSregisterReceive" } },
                              [_vm._v("Đăng ký nhận SMS")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "sMScancelReceive" } },
                              [_vm._v("Hủy đăng ký SMS")]
                            ),
                            _vm.deleteStatusButton
                              ? _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "studentsDelete" } },
                                  [_vm._v("Xóa học sinh")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "excelImport" } },
                      [_vm._v("Nhập File Excel")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "excelExport" } },
                      [_vm._v("Xuất File Excel")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "excelMessageParent" } },
                      [_vm._v("Xuất Excel lời nhắn\n          ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "excelMedicine" } },
                      [_vm._v("Xuất Excel dặn thuốc\n          ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "excelAbsentLetter" } },
                      [_vm._v("Xuất Excel Xin nghỉ\n          ")]
                    ),
                    _c("el-dropdown-item", { attrs: { command: "excelSMS" } }, [
                      _vm._v("Xuất Excel SMS"),
                    ]),
                    _c("el-dropdown-item", { attrs: { command: "groupOut" } }, [
                      _vm._v("Ra trường"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.studentList,
                "highlight-current-row": "",
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  fixed: "",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  align: "center",
                  label: "STT",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  align: "center",
                  label: "Mã HS",
                  prop: "kidCode",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { "min-width": "170", fixed: "", label: "Họ tên" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "click-in-row",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.clickFullName(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.fullName) + "\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Biệt danh",
                  prop: "nickName",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "maClass.className",
                  "min-width": "130",
                  label: "Lớp",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "130",
                  label: "Ngày nhập học",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.dateStart))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120",
                  label: "Ngày sinh",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gender",
                  "min-width": "80",
                  label: "Giới tính",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "SĐT-SMS", align: "center", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Đổi số điện thoại nhận SMS",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "click-in-row",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePhoneSMSDialogMethod(
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "110", label: "Tài khoản", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.username == "Xử lý"
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "handle-account",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.accountHanedleDialogMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "≪" + _vm._s(scope.row.username) + "≫"
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : scope.row.username == "Chưa có"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "Thiếu tên hoặc số điện thoại người đại diện",
                                  placement: "top",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(scope.row.username))])]
                            )
                          : _c(
                              "span",
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content:
                                        "Mật khẩu: " + scope.row.password,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.username)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "smsReceive",
                  width: "60",
                  label: "SMS",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled: !_vm.checkPermission([
                              "kids_list_update",
                            ]),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.activeOneSMSMethod(
                                scope.$index,
                                scope.row
                              )
                            },
                          },
                          model: {
                            value: scope.row.smsReceive,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "smsReceive", $$v)
                            },
                            expression: "scope.row.smsReceive",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Kích hoạt", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            disabled:
                              !_vm.checkPermission(["kids_list_update"]) ||
                              _vm.dataSearch.status === "LEAVE_SCHOOL"
                                ? true
                                : false,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.activeOneMethod(
                                scope.$index,
                                scope.row
                              )
                            },
                          },
                          model: {
                            value: scope.row.activated,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "activated", $$v)
                            },
                            expression: "scope.row.activated",
                          },
                        }),
                        scope.row.login == "loginYes"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Đã đăng nhập",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#409eff",
                                  },
                                }),
                              ]
                            )
                          : scope.row.login == "loginNo"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Không đăng nhập",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#e6a23c",
                                  },
                                }),
                              ]
                            )
                          : scope.row.login == "loginYet"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "Chưa từng đăng nhập",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-check",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "red",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm.checkPermission(["kids_list_update"])
                ? _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: "Thao tác",
                      width: _vm.deleteStatusButton ? 240 : 170,
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Sửa\n          ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.dataSearch.status == "LEAVE_SCHOOL"
                                        ? true
                                        : false,
                                    size: "mini",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateKidsStatusMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Nghỉ học\n          ")]
                              ),
                              _vm.deleteStatusButton
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xóa\n          ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2886177427
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paging-click row-data" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.pageNumber,
              "page-sizes": _vm.$pageListDefaultUser,
              "page-size": _vm.maxPageItem,
              layout: _vm.$pageLayoutDefault,
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("CreateStudent", {
        ref: "CreateStudent",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
      _c("UpdateStudent", {
        ref: "UpdateStudent",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
      _c("ChangeClassStudent", {
        ref: "ChangeClassStudent",
        attrs: { dialogVisible: _vm.showChangeClassDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.diglogCloseChangeClassMethod()
          },
        },
      }),
      _c("CreateStudentNotify", {
        ref: "CreateStudentNotify",
        attrs: {
          dataKidNotifyList: _vm.dataKidNotifyList,
          dialogVisibleEx: _vm.showCreateNotifyDialog,
          listClassSelect: _vm.listClassSelect,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateNotifyMethod()
          },
        },
      }),
      _c("CreateStudentNotifySms", {
        ref: "CreateStudentNotifySms",
        attrs: {
          dataKidNotifySmsList: _vm.dataKidNotifySmsList,
          dialogVisibleSms: _vm.showCreateNotifySmsDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateNotifySmsMethod()
          },
        },
      }),
      _c("AccountHandle", {
        ref: "AccountHandle",
        attrs: { dialogVisible: _vm.showAccountHandeDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseAccountHandleMethod()
          },
        },
      }),
      _c("ChangePhoneSMS", {
        ref: "ChangePhoneSMS",
        attrs: { dialogVisible: _vm.showChangePhoneSMSDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseChangePhoneSMSMethod()
          },
        },
      }),
      _c("CreateStudentExcel", {
        ref: "CreateStudentExcel",
        attrs: {
          gradeList: _vm.gradeOfSchoolList,
          dialogVisibleExStudent: _vm.showCreateStudentExcelDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateStudentExcelMethod()
          },
        },
      }),
      _c("KidsUpdateStatusDialog", {
        ref: "KidsUpdateStatusDialog",
        attrs: { dialogVisible: _vm.showUpdateKidsStatus },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeUpdateKidsStatus()
          },
        },
      }),
      _c("CreateSmsDialog", {
        ref: "CreateSmsDialog",
        attrs: { dialogVisible: _vm.showCreateSms },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeCreateSmsMethod()
          },
        },
      }),
      _c("CreateGroupOutDialog", {
        ref: "CreateGroupOutDialog",
        attrs: {
          listKids: _vm.multipleSelection,
          dialogVisible: _vm.showCreateGroupOutDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeCreateGroupOutDialog()
          },
        },
      }),
      _c("KidsSearchExtendDialog", {
        ref: "KidsSearchExtendDialog",
        attrs: {
          status: _vm.dataSearch.status,
          dialogVisible: _vm.showSearchExtend,
        },
        on: { "dialog-close": _vm.closeSearchExtend },
      }),
      _c("ExportMedicineDialog", {
        ref: "ExportMedicineDialog",
        attrs: { dialogVisible: _vm.showExportMedicineDialog },
        on: { "dialog-close": _vm.closeExportMedicineDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }